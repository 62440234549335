$backgroundColor: #f5f6fa;
$selectedColor: #dcdde1;
$groupColor: #7f8fa6;

.autocomplete {
}

.ag-cell-editor-autocomplete {
  background-color: $backgroundColor;
  z-index: 1000;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
  border: 1px solid rgba(50, 50, 50, 0.6);
  max-height: 25% !important;
  max-width: 25% !important;
}

.ag-cell-editor-autocomplete * {
    font: inherit;
}

.ag-cell-editor-autocomplete > div {
  margin: 0;
}

.ag-cell-editor-autocomplete > div.group {
  background-color: $groupColor;
  color: white;
}

.ag-cell-editor-autocomplete > div:hover:not(.group),
.ag-cell-editor-autocomplete > div.selected {
    background: $selectedColor;
    cursor: pointer;
}

.ag-cell-editor-autocomplete > div > * > strong {
  font-weight: bold !important;
}
